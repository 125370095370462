@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #242424;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
    height: 200px;
    border-radius: 1em;
    padding: 1em;
    color: white;
    backdrop-filter: blur(5px) saturate(160%);
    -webkit-backdrop-filter: blur(5px) saturate(160%);
    background-color: rgba(53, 63, 82, 0.72);
    border: 1px solid rgba(255, 255, 255, 0.125);
    transition: 0.5s;
}

.card:hover {
    border: 1px solid rgba(255, 255, 255, 0.5);
}

@media (max-width: 812px) {
    .card {
        margin: 0 10px;
    }
}
.bg-darker {
    background-color: rgb(23,27,31);
    color: white;
}

.bg-lighter {
    background-color: rgb(63,67,71);
    color: gray;
}

.input-custom-1 {
    font-family: 'Roboto Mono', monospace;
    text-align: end;
    color: white;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1.6em;
    font-weight: 700;
}

.mw-480px {
    max-width: 480px;
}

.rounded-5 {
    border-radius: 1em;
}

.text-gray {
    color: #979EAF;
}

.swap-bottom {
    padding: 1em;
    font-size: 0.7em;
}
