.card {
    height: 200px;
    border-radius: 1em;
    padding: 1em;
    color: white;
    backdrop-filter: blur(5px) saturate(160%);
    -webkit-backdrop-filter: blur(5px) saturate(160%);
    background-color: rgba(53, 63, 82, 0.72);
    border: 1px solid rgba(255, 255, 255, 0.125);
    transition: 0.5s;
}

.card:hover {
    border: 1px solid rgba(255, 255, 255, 0.5);
}

@media (max-width: 812px) {
    .card {
        margin: 0 10px;
    }
}